import request from "./request.js";
import qs from "qs"; 

if (process.env.NODE_ENV === "development") {
    var baseUrl = 'https://test-openv2.oouu.cc'//开发环境  
} 
// else {  
//     var baseUrl = 'https://virtuaiapi.oouu.cc'//生产环境
// }
// 登录
export function authCodeLogin(data) {
    return request({
        url:  "/api/user/login",
        method: "post",
        data: data
    });
}
// 获取登录微信二维码
export function getLoginQrCode() {
    return request({
        url:  "/api/user/wx-login",
        method: "get",
    });
}
// 扫码登录

export function scanCodeLogin(params) {
    return request({
        url:  "/api/user/wx-islogin",
        method: "get",
        params:params
    });
}
// 交易中心
export function getTransactionList(params) {
    return request({
        url: "/api/user/trading",
        method: "get",
        params:params
    });
}
// 数字人
export function getDigitalManList(params) {
    return request({
        url: "/api/user/digital",
        method: "get",
        params:params
    });
}

// tts音频
export function getTtsAudioList(params) {
    return request({
        url: "/api/user/tts",
        method: "get",
        params:params
    });
}
// 获取账户信息

export function getUserInfor(params) {
    return request({
        url: "/api/user/info",
        method: "get",
        params:params
    });
}

// 修改登录账户
export function modifyLoginAccount(data) {
    return request({
        url:  "/api/user/user-username",
        method: "post",
        data: data
    });
}
// 修改登录密码
export function changePassword(data) {
    return request({
        url:  "/api/user/user-password",
        method: "post",
        data: data
    });
}
// 以前用户绑定微信登录
export function oldUserBinding() {
    return request({
        url: "/api/user/wx-bind",
        method: "get",
    });
}
// 轮询绑定是否成功
export function pollingBinding(params) {
    return request({
        url:  "/api/user/wx-isbind",
        method: "get",
        params:params
    });
}

// 添加IP白名单
export function addIpWhite(data) {
    return request({
        url:  "/api/user/setIp",
        method: "post",
        data: data
    });
}

// 支付
export function pay(data) {
    return request({
        url:  "/api/user/recharge",
        method: "post",
        data: data
    });
}

//查询支付接口
export function queryPay(params) {
    return request({
        url:  "/api/user/ispay",
        method: "get",
        params:params
    });
}

// 交易流水
export function getTransactionRecord(params) {
    return request({
        url:  "/api/user/recharge-log",
        method: "get",
        params:params
    });
}





// 
export function editUserInfor(data) {
    return request({
        url:  "",
        method: "put",
        data: data
    });
}
export function deleteSoundModel(data) {
    return request({
        url:  "",
        method: "delete",
        data: data
    });
}







//params是添加到url的请求字符串中的，用于get请求。
//data是添加到请求体（body）中的， 用于post、put、delete请求。