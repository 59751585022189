import router from "./index";
import {whiteList, tokenKey} from '@/utils/config'
import store from "@/store";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// 路由前置守卫
router.beforeEach(async (to, from, next) => {
    // 开始进度条加载
    NProgress['start']();
    
    // 如果是从 TtsAudio 页面切换出去，或者切换到 TtsAudio 页面，延迟执行
    if (to.name === 'TtsAudio' || from.name === 'TtsAudio') {
        setTimeout(async () => {
            await store.dispatch('menu/updateMenuList')
            const token = localStorage.getItem(tokenKey)
            const hasRoute = router.getRoutes().filter(item => item.path === to.path).length === 0
            
            if (whiteList.includes(to.path)) {
                next()
            } else if (token) {
                if (to.path === '/index') {
                    next('/home')
                } else if (to.matched.length <= 1) {
                    if (hasRoute) {
                        next('/404')
                    } else {
                        next(to.path);
                    }
                } else {
                    next()
                }
            } else {
                next('/index')
            }
            // 结束进度条加载
            NProgress['done']();
        }, 100);
    } else {
        await store.dispatch('menu/updateMenuList')
        const token = localStorage.getItem(tokenKey)
        const hasRoute = router.getRoutes().filter(item => item.path === to.path).length === 0
        
        if (whiteList.includes(to.path)) {
            next()
        } else if (token) {
            if (to.path === '/index') {
                next('/home')
            } else if (to.matched.length <= 1) {
                if (hasRoute) {
                    next('/404')
                } else {
                    next(to.path);
                }
            } else {
                next()
            }
        } else {
            next('/index')
        }
        // 结束进度条加载
        NProgress['done']();
    }
})