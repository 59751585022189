<template>
    <div class="topUp-container">
        <div class="topUp-title">
            <span>选择需要充值的金额</span>
        </div>
        <div class="topUp-content">
            <span>充值金额（元）与积分比例为1：150</span>
        </div>
        <div class="options-qrCode">
            <div class="select">
                <div class="list">
                    <div class="common" @click="selectSmeal(index)" :class="setMealIndex == index ? 'active' : 'item'"
                        v-for="(item, index) in list" :key="index">
                        <!-- 赠送 -->
                        <!-- <div class="gift" v-if="item.gift">
                            送{{ item.gift }}积分
                        </div> -->
                        <div class="price unify">
                            ￥{{ item.price }}
                        </div>
                        <div class="points unify">
                            {{ item.points }}积分
                        </div>
                        <div class="name unify">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <!-- 输入自定义金额 -->
                <div class="custom" v-if="!customPay" :style="customShow ? 'background:#fff' : ' background:#F0F1F1'">
                    <div class="tips" @click="customRecharge" v-if="!customShow">
                        <div class="title">自定义金额</div>
                        <span>最高10万元</span>
                    </div>
                    <div class="customTopUp" v-if="customShow">
                        <div class="input">
                            <el-input v-model="customValue" oninput="value=value.replace(/[^\d.]/g,'')"
                                style="width: 240px" placeholder="输入充值金额">
                                <i slot="prefix" style="font-style: normal;line-height: 40px;color: #000; ">￥</i>
                            </el-input>
                        </div>
                        <div class="cancel-verify">
                            <el-button size="small" @click="customShow = false">取消</el-button>
                            <el-button type="primary" size="small" @click="payVerify">确认</el-button>
                        </div>
                    </div>
                </div>
                <!-- 自定义金额确认 -->
                <div class="custom-verify-box" v-if="customPay">
                    <!-- <div class="gift">送{{ 10000 }}积分</div> -->
                    <div class="price">￥{{ customValue }} <span @click="edit">修改</span>
                    </div>
                    <p class="points">{{ customValue * 150 }}积分</p>
                </div>
                <!-- 提示 -->
                <!-- <div class="warn">
                    <div class="icon"><i class="el-icon-warning-outline"></i></div>
                    <div>
                        <p>充值金额＜1000 元，无额外积分赠送；</p>
                        <p> 1000 元≤充值金额＜10000 元，每满 1000 元赠 10000 积分；</p>
                        <p>充值金额≥10000 元，每满 10000 元赠 150000 积分。</p>
                    </div>
                </div> -->

            </div>
            <div class="qrCode">
                <h1>应付金额</h1>
                <p class="price"><span class="number">{{ amountPayable }}</span><span class="text">元</span></p>
                <div class="payCode">
                    <div class="back"><img src="@/assets/codeBack.png" alt=""></div>
                    <div class="noPrice" v-if="noPriceShow"><img src="@/assets/exclaimOverMax.png" alt=""></div>
                    <div class="payImage" v-if="payImage">
                        <img :src="qrCodeUrl" alt="支付二维码">
                    </div>
                    <div class="loding"> <i v-if="lodingShow" class="el-icon-loading"></i></div>
                </div>
                <p class="payText"><span><img src="@/assets/wxPay.png" alt=""></span><span
                        style="margin-left: 5px;">请微信扫码支付</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import { pay,queryPay } from '@/api/api.js'
import QRCode from 'qrcode'

export default {
    name: "TopUp",
    data() {
        return {
            list: [ 
                {
                    price: '200',
                    points: '30000',
                    name: '新人套餐',
                    gift: ''
                }, {
                    price: '3000',
                    points: '450000',
                    name: '豪华套餐',
                    gift: '30000'
                }, {
                    price: '10000',
                    points: '1500000',
                    name: '至尊套餐',
                    gift: '150000'
                }
            ],
            setMealIndex: '-1',//套餐索引
            customShow: false,//自定义 是否显示
            customValue: '',//自定义金额
            amountPayable: '0',//应付金额
            customPay: false,//自定义充值确定
            timer: null,// 轮询计时器
            countdown: 30, // 倒计时时间
            time: null,//防抖
            noPriceShow: true,//无二维码
            payImage: false,//支付二维码
            lodingShow: false,
            qrCodeUrl: '' // 二维码图片的URL
        };
    },
    computed: {
        heha() {
            if (this.setMealIndex !== '-1' || this.customPay) {
                // console.log('开启轮询')
                return true
            } else {
                // console.log('关闭轮询')
                return false
            }
        }
    },

    methods: {
        // 自定义防抖函数
        debounce(func, wait) {
            let timeout;
            return function (...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        },
        // 点击自定义充值
        customRecharge() {
            this.customShow = true;
            this.setMealIndex = '-1'
            this.stopPolling()

            this.noPriceShow = true;//无二维码
            this.payImage = false;//支付二维码
            this.lodingShow = false
        },
        // 自定义金额修改
        edit() {
            this.stopPolling()
            this.customPay = false;
            this.customShow = true
            this.noPriceShow = true;//无二维码
            this.payImage = false;//支付二维码
            this.lodingShow = false
        },
        // 选择套餐 - 开启防抖
        selectSmeal(index) {
            let that = this
            that.setMealIndex = index;
            that.customShow = false;
            that.customPay = false;
            that.customValue = '';
            that.amountPayable = that.list[index].price;
            this.stopPolling()
            that.debouncedPrePollOperation();

            this.payImage = false
            that.noPriceShow = false
            that.lodingShow = true
        },
        // 自定义充值确认
        payVerify() {
            if (this.customValue > 100000) {
                this.$message.error('充值金额最高不能超过10万元');
                return;
            }
            this.customPay = true;
            this.amountPayable = this.customValue;
            this.prePollOperation();
            this.noPriceShow = false//无二维码
            this.lodingShow = true

        },
        // 启动轮询
        startPolling() {
            this.stopPolling(); // 确保没有重复的轮询
            this.countdown = 300;
            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                    this.poll(); // 调用轮询接口
                } else {
                    this.stopPolling();
                    // console.log('轮询结束');
                }
            }, 1000);
        },
        // 停止轮询
        stopPolling() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
                this.noPriceShow = true//无二维码
                this.payImage = false//支付二维码
            }
        },
        // 轮询接口
        async poll() {
            try {
                console.log('轮询中...');
                let params = {
                    ordersn: this.ordersn
                }
                queryPay(params).then(res => {
                    // console.log(res)
                    if (res.data.code == 200) {
                        if (res.data.data.isPay == 1) {
                            this.stopPolling()

                            this.$message.success('支付成功');
                            this.$emit('payOk')
                        }
                    }
                })
               
            } catch (error) {
                console.error('轮询请求失败:', error);
            }
        },
        // 预操作
        async prePollOperation() {
            try {
                // console.log('执行预操作...');
                // 示例：预操作逻辑
                let data = {
                    total: this.amountPayable
                }
                const res = await pay(data);
                // console.log(res)
                if (res.data.code == 200) {
                    // console.log(res.data.data.url)
                    // 将URL转换为二维码
                    const qrCode = await QRCode.toDataURL(res.data.data.url);
                    this.qrCodeUrl = qrCode;
                    this.ordersn = res.data.data.ordersn;

                    this.payImage = true;
                    this.lodingShow = false;
                    this.startPolling()
                } else {
                    throw new Error('预操作失败');
                }
            } catch (error) {
                console.error('预操作失败:', error);
                this.$message.error('预操作失败，请稍后再试');
                this.lodingShow = false;
            }
        }
    },
    created() {
        // 初始化防抖函数
        this.debouncedPrePollOperation = this.debounce(this.prePollOperation, 1000);
    }
};
</script>

<style scoped lang="less">
.topUp-title {
    font-size: 24px;
    font-weight: 500;
    color: #231F20;
    line-height: 50px;
}

.topUp-content {
    font-size: 14px;
    color: #939393;
}

.options-qrCode {
    display: flex;
    justify-content: space-between;

    .select {
        margin: 15px 0;

        .list {
            display: flex;

            .common {
                border-radius: 8px;
                margin-right: 20px;
                position: relative;
                cursor: pointer;
                background: #F0F1F1;

                .gift {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 110px;
                    height: 27px;
                    border-radius: 8px 0px 8px 0px;
                    opacity: 1;
                    /* 蓝青 */
                    background: linear-gradient(260deg, #864AFE -30%, #217FFD 83%);
                    line-height: 27px;
                    color: #fff;
                    text-align: center;
                }

                .price {
                    text-align: center;
                    font-size: 28px;
                    color: #3D3D3D;
                    font-weight: 700;
                    margin-top: 40px;
                }

                .points {
                    text-align: center;
                    line-height: 30px;
                }

                .name {
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;

                    span {
                        text-align: center;
                        padding: 7px;
                        text-align: center;
                        border: 1px solid #3B82F6;
                        border-radius: 8px;
                    }
                }

            }

            .active {
                width: 168px;
                height: 198px;
                border: 1px solid #3B82F6;
            }

            .item {
                width: 170px;
                height: 200px;
            }
        }

        .custom {
            margin-top: 20px;
            width: 550px;
            height: 100px;
            border-radius: 8px;

            .tips {
                text-align: center;
                cursor: pointer;

                .title {
                    padding-top: 25px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #3D3D3D;
                }

                span {
                    line-height: 50px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .customTopUp {
                .input {
                    padding: 10px 25px;

                    /deep/.el-input__inner {
                        height: 40px;
                        width: 500px;
                    }
                }

                .cancel-verify {
                    padding: 0 25px;
                    display: flex;
                    justify-content: right;
                }

            }
        }

        .custom-verify-box {
            margin-top: 20px;
            width: 550px;
            height: 100px;
            border-radius: 8px;
            position: relative;
            background-color: #fff;

            .gift {
                position: absolute;
                top: 0;
                left: 0;
                width: 110px;
                height: 27px;
                border-radius: 8px 0px 8px 0px;
                opacity: 1;

                /* 蓝青 */
                background: linear-gradient(260deg, #864AFE -30%, #217FFD 83%);
                color: #fff;
                text-align: center;
                line-height: 27px;
            }

            .price {
                text-align: center;
                font-size: 28px;
                font-weight: bold;
                color: #3B82F6;
                padding-top: 30px;

                span {
                    color: #FA5151;
                    font-size: 14px;
                    font-weight: normal;
                    cursor: pointer;
                }
            }

            .points {
                font-size: 14px;
                color: #3B82F6;
                text-align: center;
                line-height: 30px;
            }
        }

        .warn {
            margin-top: 10px;
            display: flex;
            line-height: 20px;
        }
    }

    .qrCode {
        margin: 15px 0;
        width: 200px;
        height: 320px;
        // border: 1px solid #ccc;

        h1 {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #3D3D3D;
            line-height: 50px;
        }

        .price {
            text-align: center;
            color: #3B82F6;

            .number {
                font-size: 36px;
            }

            .text {
                font-size: 14px;
            }
        }

        .payCode {
            position: relative;

            .back {
                display: flex;
                justify-content: center;
            }

            .noPrice {
                position: absolute;
                left: 50%;
                bottom: 20px;
                transform: translateX(-50%);
            }

            .payImage {
                position: absolute;
                left: 50%;
                bottom: 20px;
                transform: translateX(-50%);
                width: 130px;
                height: 130px;
                background-color: #fff;

                img {
                    margin: 5px;
                    width: 120px;
                    height: 120px;
                }
            }

            .loding {
                position: absolute;
                left: 50%;
                bottom: 60px;
                transform: translateX(-50%);

                i {
                    color: #fff;
                    font-size: 30px;
                }
            }
        }

        .payText {

            display: flex;
            justify-content: center;
            margin-top: 10px;
            line-height: 18px;
        }
    }
}
</style>