<template>
  <div class="header">
    <div class="header-top">
      <div class="menu-box">
        <!-- <el-button icon="el-icon-s-fold" v-show="!isCollapse" @click="handleCollapse" />
        <el-button icon="el-icon-s-unfold" v-show="isCollapse" @click="handleCollapse" /> -->
        <el-breadcrumb separator="/" style="margin-left: 20px;">
          <el-breadcrumb-item v-for="(title, index) in titles" :key="index + 'breadcrumb-item'">{{ title
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="info-box">
        <div class="topUp" @click="topUpShow = true">
          充值
        </div>
        <el-popover placement="bottom-end" width="200" trigger="hover">
          <div>
            <p class="points">剩余点数：{{ points }}</p>
            <p class="time">到期时间：{{ maturityTime }}</p>
            <p class="logOut" @click="logout">退出登录</p>
          </div>
          <span class="userInfo-box" slot="reference">
            <img class="avatar" src="@/assets/logo.png" alt="">
            <span class="user-name">{{ username }}</span>
          </span>
        </el-popover>
      </div>
    </div>
    <div class="header-bottom">
      <Tags />
    </div>
    <!-- 充值弹框 -->
    <el-dialog custom-class="topUpBox" ref="closeTopUp" :visible.sync="topUpShow" width="800px" :show-close="false"
      @close="closeTopUp">
      <topUp @payOk="payOk">
      </topUp>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Tags from "@/layout/main/components/Tags.vue";
import { emitter } from '@/utils/tools/Emitter'
import _ from 'lodash'
import topUp from '../../views/management/AccountInformation/commons/topUp.vue'
import { getUserInfor } from '@/api/api.js'
export default {
  name: "HeaderView",
  components: { Tags, topUp },
  data() {
    return {
      titles: [],
      username: '',
      points: '',//剩余点数
      maturityTime: '',//到期时间
      isCollapsing: false,  // 添加状态锁
      topUpShow: false
    }
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.menu.isCollapse,
      userInfo: (state) => state.user.userInfo
    })
  },
  watch: {
    $route: {
      handler(val) {
        this.titles = val.meta['titles']
      },
      immediate: true
    }
  },
  mounted() {
    this.points = JSON.parse(localStorage.getItem('userInfo')).dianshu
    this.maturityTime = JSON.parse(localStorage.getItem('userInfo')).endtime
    this.username = JSON.parse(localStorage.getItem('userInfo')).username
  },
  methods: {
    getUserInfor() {
      let params = {
        token: localStorage.getItem('token')
      }
      getUserInfor(params).then(res => {
        if (!res) return
        if (res.data.code == 200) {
          let data = JSON.parse(localStorage.getItem('userInfo'))
          this.points = res.data.data.dianshu
          data.dianshu = res.data.data.dianshu
          localStorage.setItem('userInfo', JSON.stringify(data))
        }
      })
    },


    ...mapMutations({
      setCollapse: 'menu/setCollapse'
    }),
    /**
     * ## 点击菜单项触发的事件回调
     * @param {string} command dropdown-item 的指令
     */
    handleCommand() {
      if (command === 'logout') {
        this.logout()
      }
    },
    payOk() {
      this.topUpShow = false
      this.getUserInfor()
    },
    closeTopUp() {
      this.$refs.closeTopUp.$children[0].setMealIndex = '-1'
      this.$refs.closeTopUp.$children[0].customPay = false
      this.$refs.closeTopUp.$children[0].customShow = false
      this.$refs.closeTopUp.$children[0].noPriceShow = true,//无二维码
        this.$refs.closeTopUp.$children[0].payImage = false,//支付二维码
        this.$refs.closeTopUp.$children[0].lodingShow = false
      this.$refs.closeTopUp.$children[0].stopPolling()//关闭查询支付轮询
      //    console.log( this.$refs.closeTopUp.$children[0])
    },
    logout() {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 触发退出登录事件
        emitter.$emit('API_UN_AUTH')
        localStorage.removeItem('token');
      }).catch(action => {

      })
    },
    handleCollapse: _.debounce(function () {
      if (this.isCollapsing) return;  // 如果正在切换中，则不执行
      this.isCollapsing = true;
      this.setCollapse();
      setTimeout(() => {
        this.isCollapsing = false;
      }, 300);
    }, 300)
  }
}
</script>



<style scoped lang="less">
.header {
  height: 100px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.header button {
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  border: none;
  font-size: 24px;
}

.header-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.menu-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-box {
  display: flex;

  .topUp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
    color:deepskyblue;
  }

  .logOut {
    cursor: pointer;
  }

  .userInfo-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;
    margin-right: 60px;

    .user-name {
      font-size: 14px
    }
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}


.header-bottom {
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.el-popover {
  p {
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .logOut {
    text-align: center;
    border-top: 1px solid #eee;
    cursor: pointer;
  }
}
</style>