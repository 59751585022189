/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message } from 'element-ui';
import router from '@/router'

//1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--这里注意后面会讲
    baseURL: process.env.BASE_API,
    // 超时时间 单位是ms，这里设置了5s的超时时间
    // timeout: 10 * 1000
})

// 2.请求拦截器
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等

    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
        'Content-Type': 'application/json',//配置请求头
        'X-Requested-With': 'XMLHttpRequest',
        'token': localStorage.getItem('token') || '',
    }

    return config
}, error => {
    return Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if(!response){
        Message({
            message: response.data.msg || '出错了！',
            type: 'error',
            duration: 3 * 1000
        })
    }
   if(response){
    if (response.status !== 200) {
        Message({
            message:response.data.msg || '出错了！',
            type: 'error',
            duration: 3 * 1000
        })
        return
    } else if (response.status == 200 && response.data.code == 999) {
        Message.error('登录状态已过期,请重新登录')
        localStorage.removeItem('token')
        router.push('/login')
        return
    } else {
        return response
    }
   }

}, error => {
    Message({
        // message: error.message,
        message: '服务器错误，请稍后再试！',
        type: 'error',
        duration: 5 * 1000
    })
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})
//4.导入文件
export default service